.footer {
    background-color: #2f3c7e;
    text-align: center;
    margin: 0 auto;
    padding: 0 0;
    clear: both
}
  
.footer .footer-body {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.footer h3 {
    font-size: 1em;
    color: white;
}

.footer ul {
    padding: 20px 0;
}

.footer li {
    display: inline-block;
    padding: 15px;
}

.footer .social-icons {
    font-size: 24px;
}

@media screen and (max-width: 1024px) {
    .footer {
        padding: 20px 20px;
    }
}