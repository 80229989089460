@media (min-width: 1200px){
	.container, .container-sm, .container-md, .container-lg, .container-xl {
	    max-width: 1170px;
	}
	
}


/*------------------------------------------------------------------------------------
/ 2. Desktop Device
/--------------------------------------------------------------------------------------*/
@media (min-width: 992px) and (max-width: 1199px){
	
	.navbar.navbar-expand-lg {
	    margin: 0 -20px;
	    padding: 0 20px 0 0;
	}
	.navbar-brand{
		padding: 37px 20px;
	}
	.navbar-expand-lg .navbar-nav{
		padding-left: 80px;
	}
	.navbar-expand-lg .navbar-nav li{
		margin-right: 30px;
	}
	.banner-content{
		margin-left: 0;
	}
	.banner-content h1{
		font-size: 70px;
	}
    .sec-title{
    	font-size: 45px;
    	line-height: 60px;
    }
	.contct-box{
		margin-left: 0;
		padding-left: 105px;
	}
	.contct-box i{
		left: 25px;
	}
	.contct-box:last-child p br{
		display: none;
	}
	.contact-page-form{
		padding-left: 30px;
		padding-right: 30px;
	}

}
/*------------------------------------------------------------------------------------
/ 3. Responsive For Tab Device
/--------------------------------------------------------------------------------------*/
@media (min-width: 768px) and (max-width: 991px){

	.ab-container {
		width: auto;
	}
	
    .banner-thumb{
    	position: relative;
    	bottom: 0;
    	text-align: center;
    	right: 0;
    	animation-name: fadeInUp;
    }

}
/*------------------------------------------------------------------------------------
/ 4. Responsive For Mobile & Tab
/--------------------------------------------------------------------------------------*/
@media (max-width: 991px){

	.navbar.navbar-expand-lg{
    	padding: 0 15px 0 0;
    	margin: 0 -15px;
    }
    .navbar-expand-lg .navbar-nav{
    	padding: 0;
    }
    .navbar-brand{
    	padding: 18px 30px;
    }
    .navbar.navbar-expand-lg .navbar-toggler {
	    width: 45px;
	    height: 30px;
	    padding: 8px;
	    outline: none;
	    border-radius: 5px;
	    border: 1px solid rgba(0,0,0,0.2);
	}
	.navbar.navbar-expand-lg .navbar-toggler .navbar-toggler-icon {
	    display: block;
	    width: 100%;
	    height: 1px;
	    margin: 0 0 4px;
	    background: rgba(0,0,0,0.5);
	}
	.navbar-expand-lg .navbar-nav li .nav-link{
		padding: 18px 20px;
		color: #fff;
	}
	.navbar-expand-lg .navbar-nav li{
		margin: 0;
		border-bottom: 1px solid rgba(255, 255, 255, .1);
	}
	.navbar-expand-lg .navbar-nav li:last-child{
		border: none;
	}
	.navbar-expand-lg .navbar-nav li .nav-link:before{
		display: none;
	}
	.navbar-expand-lg .navbar-nav li.dropdown .nav-link:after{
		content: "↓";
		font-family: 'Font Awesome 5 Pro';
		color: #fff;
		font-size: 16px;
		line-height: 56px;
		position: absolute;
		right: 15px;
		top: 0;
	}
	.navbar.navbar-expand-lg .navbar-collapse {
	    position: absolute;
	    left: 0;
	    top: 100%;
	    width: 100%;
	    background: #14212b;
	    border-radius: 0;
	    z-index: 99;
	}
	.navbar-expand-lg .navbar-nav li .dropdown-menu{
		position: relative;
	    visibility: visible;
	    opacity: 1;
	    width: 100%;
	    background: #fff;
	    left: 0;
	    top: 0;
	    display: none;
	    transform: none;
	    transition: none;
	    box-shadow: none;
	    transition: all .4s ease;
	}
	.navbar-expand-lg .navbar-collapse.show > li .dropdown-menu {
	    display: block;
	    top: 0;
	    transition: all .4s ease;
	}
	.navbar-expand-lg .navbar-nav li .dropdown-menu li{
		border-color: rgba(0, 0, 0, .1);
	}
	.navbar-expand-lg .navbar-nav li.dropdown .dropdown-menu li a.nav-link:after{
		color: #14212b;
		line-height: 46px;
	}
	.navbar-expand-lg .navbar-collapse .navbar-nav li .dropdown-menu li .dropdown-menu{
		visibility: visible;
		opacity: 1;
		top: 0;
		left: 0;
		display: none;
	}
	.navbar-expand-lg .navbar-collapse.show .navbar-nav li .dropdown-menu li .dropdown-menu.show {
	    display: block;
	    transition: all .4s ease;
	}
	.navbar-expand-lg .navbar-nav li .dropdown-menu li .dropdown-menu{
		right: auto;
		left: 0;
		position: relative;
		width: 100%;
		display: none;
		background: #14212b;
	}
	.navbar-expand-lg .navbar-nav li .dropdown-menu li .dropdown-menu li{
		border-color: rgba(255, 255, 255, .1);
	}
	.navbar-expand-lg .navbar-nav li .dropdown-menu li .dropdown-menu li a{
		color: #fff;
	}
	.navbar-expand-lg .navbar-nav li .dropdown-menu li .dropdown-menu li a:hover{
		color: #ff4a17;
	}
	.navbar-expand-lg .navbar-collapse.show .navbar-nav li .dropdown-menu li .dropdown-menu.show{
	    display: block;
	    transition: all .4s ease;
	}
	.navbar-expand-lg .navbar-nav li .dropdown-menu.show{
		display: block;
	}

	.hero-banner{
    	padding: 300px 0 250px;
    }
    .banner-content{
    	margin-left: 0;
    }

    .banner-content h1{
    	font-size: 70px;
    }
    .sec-title{
    	font-size: 36px;
    	line-height: 46px;
    }
   
	.page-content{
		margin-left: 15px;
	}
	.page-content h2{
		font-size: 36px;
		line-height: 38px;
	}
	.service-title{
		padding-left: 20px;
		padding-right: 20px;
		width: calc(100% - 40px);
	}
	.service-title h3{
		font-size: 36px;
		line-height: 44px;
	}
	.contct-box{
		margin: 40px 0 0;
	}
	.contact-page-form{
		padding-left: 30px;
		padding-right: 30px;
	}

}